import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {map, isEqual, some} from "lodash";
import limApi from "../../apis/limApi";
import {deleteAddressBookAddress, getAddressBookAddresses} from "./addressBookAddressSlice";
import {deleteAddressBookGroup, getAddressBookGroups, postAddressBookGroup} from "./addressBookGroupSlice";
import {selectUserIdClient, selectUserIdClientAccount} from "./userSlice";
import {getGroups} from "./groupsSlice";

export const getAddressBooks = createAsyncThunk(
    'addressBook/fetchAll',
    async (_, {rejectWithValue, getState}) => {
        const idClient = selectUserIdClient(getState());
        const idClientAccount = selectUserIdClientAccount(getState());

        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/addressbooks?pageSize=1000`);
            return response.data.addressbooks;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getAddressBook = createAsyncThunk(
    'addressBook/fetchOne',
    async ({idClient, idClientAccount, idAddressBook}, {rejectWithValue, dispatch}) => {
        try {
            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/addressbooks/${idAddressBook}`);
            await dispatch(getAddressBookGroups({idClient, idClientAccount, idAddressBook}));
            await dispatch(getGroups({idClient, idClientAccount}));

            return response.data.addressbook;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postAddressBooks = createAsyncThunk(
    'addressBook/create',
    async ({idClient, idClientAccount, addressBook}, {rejectWithValue, dispatch}) => {
        try {
            const book = await limApi
                .post(`/clients/${idClient}/accounts/${idClientAccount}/addressbooks`, addressBook)
                .then(async response => await response.data.addressbook)

            for (const idClientAccountGroup of addressBook.addressBookGroups) {
                await dispatch(postAddressBookGroup({idClient, idClientAccount, idAddressBook: book.idAddressBook, data: {idClientAccountGroup}}));
            }
            return book;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putAddressBook = createAsyncThunk(
    'addressBook/update',
    async ({idClient, idClientAccount, idAddressBook, addressBook}, {rejectWithValue, getState, dispatch}) => {
        if (getState().addressBook.book.idClientAccount !== idClientAccount) {
            return rejectWithValue({code: '', message: 'Changing Account is not allowed!'})
        }

        try {
            const book = await limApi
                .put(`/clients/${idClient}/accounts/${idClientAccount}/addressbooks/${idAddressBook}`, addressBook)
                .then(response => response.data.addressbook);

            const oldAddressBookGroups = map(getState().addressBookGroup.list, (group) => group.idClientAccountGroup);
            const equal = isEqual(oldAddressBookGroups, addressBook.addressBookGroups)

            if (!equal) {
                // DELETE/REMOVE AddressBookGroups
                const deleteAddressBookGroups = oldAddressBookGroups.filter((old) => {
                    const exist = some(addressBook.addressBookGroups, old)
                    if (!exist) {
                        return old;
                    }
                    return null;
                })

                for (const idClientAccountGroup of deleteAddressBookGroups) {
                    await dispatch(deleteAddressBookGroup({idClient, idClientAccount, idAddressBook, idClientAccountGroup}))
                }
                // DELETE/REMOVE AddressBookGroups

                // ADD AddressBookGroups
                const addAddressBookGroups = addressBook.addressBookGroups.filter((idClientAccountGroup) => {
                    const exist = some(oldAddressBookGroups, idClientAccountGroup)
                    if (!exist) {
                        return idClientAccountGroup;
                    }
                    return null;
                });

                for (const idClientAccountGroup of addAddressBookGroups) {
                    await dispatch(postAddressBookGroup({idClient, idClientAccount, idAddressBook, data: {idClientAccountGroup}}));
                }
                // ADD AddressBookGroups

                await dispatch(getAddressBookGroups({idClient, idClientAccount, idAddressBook}));
            }

            return book;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const deleteAddressBook = createAsyncThunk(
    'addressBook/delete',
    async ({idClient, idClientAccount, idAddressBook}, {rejectWithValue, getState, dispatch}) => {
        try {
            await dispatch(getAddressBookAddresses({idClient, idClientAccount, idAddressBook, queryParams: "currentPage=1"}));
            await dispatch(getAddressBookGroups({idClient, idClientAccount, idAddressBook}));

            const addressBookAddress = getState().addressBookAddress.list
            if (addressBookAddress.items) {
                const deleteAddressBookAddressPromises = addressBookAddress.items.map(address => {
                    return dispatch(deleteAddressBookAddress({idClient, idClientAccount, idAddressBook, idAddressBookAddress: address.idAddressBookAddress}))
                });
                await Promise.all(deleteAddressBookAddressPromises);
            }

            const addressBookGroupPromises = getState().addressBookGroup.list.map(group => {
                return dispatch(deleteAddressBookGroup({idClient, idClientAccount, idAddressBook, idClientAccountGroup: group.idClientAccountGroup}))
            })
            await Promise.all(addressBookGroupPromises);

            await limApi.delete(`/clients/${idClient}/accounts/${idClientAccount}/addressbooks/${idAddressBook}`);
            await dispatch(getAddressBooks());
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getAddressBookUserBook = createAsyncThunk(
    'addressBook/fetchUserBook',
    async ({idUser, queryParams = "", jsonParams, addAuthUserAddressBook}, {rejectWithValue, getState}) => {
        const idClient = selectUserIdClient(getState());
        const idClientAccount = selectUserIdClientAccount(getState());

        try {
            if (!jsonParams) {
                const queryParamsObj = Object.fromEntries(
                    new URLSearchParams(queryParams)
                );

                const readOnlySort = {
                    field: "readOnly",
                    direction: "asc",
                }

                if ("sortOrders" in queryParamsObj) {
                    // attach sortOrders to existing sortOrders
                    // queryParamsObj.sortOrders = queryParamsObj.sortOrders.slice(0, -1) + "," + JSON.stringify(readOnlySort) + "]";
                    // prepend sortOrders to existing sortOrders
                    queryParamsObj.sortOrders = "[" + JSON.stringify(readOnlySort) + "," + queryParamsObj.sortOrders.slice(1);
                } else {
                    // create sortOrders if not exists
                    queryParamsObj.sortOrders = JSON.stringify([readOnlySort]);
                }

                if (addAuthUserAddressBook) {
                    queryParamsObj.addAuthUserAddressBook = addAuthUserAddressBook;
                }

                queryParams = new URLSearchParams(queryParamsObj).toString();
            }


            const response = jsonParams ?
                await limApi.put(`/clients/${idClient}/accounts/${idClientAccount}/users/${idUser}/addressbook${addAuthUserAddressBook ? '?addAuthUserAddressBook=true' : ''}`, jsonParams) :
                await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/users/${idUser}/addressbook?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

const addressBookSlice = createSlice({
    name: 'addressBook',
    initialState: {
        list: [],
        listLoading: undefined,
        book: undefined,
        userBook: undefined,
        loading: undefined
    },
    reducers: {
        resetAddressBooks: state => {
            state.list = [];
        },
        resetAddressBook: state => {
            state.book = undefined;
        },
        resetAddressBookUserBook: state => {
            state.userBook = undefined;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAddressBooks.pending, state => {
                if (state.list.length === 0) {
                    state.listLoading = undefined;
                }
            })
            .addCase(getAddressBooks.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getAddressBooks.rejected, state => {
                state.listLoading = undefined;
            })
            .addCase(getAddressBook.pending, state => {
                state.loading = true;
            })
            .addCase(getAddressBook.fulfilled, (state, action) => {
                state.loading = undefined;
                state.book = action.payload;
            })
            .addCase(getAddressBook.rejected, state => {
                state.loading = undefined;
            })
            .addCase(putAddressBook.fulfilled, (state, action) => {
                state.book = action.payload;
            })
            .addCase(getAddressBookUserBook.pending, state => {
                state.loading = true;
            })
            .addCase(getAddressBookUserBook.fulfilled, (state, action) => {
                state.loading = undefined;
                state.userBook = action.payload.items;
            })
            .addCase(getAddressBookUserBook.rejected, state => {
                state.loading = undefined;
            })
    }
});

export const {
    resetAddressBooks,
    resetAddressBook,
    resetAddressBookUserBook
} = addressBookSlice.actions;

export default addressBookSlice.reducer;