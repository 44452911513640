import { lazy } from "react";

const InboundOrders = lazy(() =>
    import("./inbound-orders/InboundOrdersList")
);
const OutboundOrders = lazy( () =>
    import('./outbound-orders/OutboundOrdersList')
)
const CustomerOrdersList = lazy( () =>
    import('./customer-orders/CustomerOrdersList')
)
const EditCustomerOrder = lazy( () =>
    import('./customer-orders/EditCustomerOrder')
)
const CustomerOrdersBulkImport = lazy( () =>
    import('./customer-orders/bulk-import/CustomerOrdersBulkImport')
)
const CustomerOrdersBulkImportWizard = lazy( () =>
    import('./customer-orders/bulk-import-wizard/BulkImportWizard')
)
const SupplierOrdersList = lazy( () =>
    import('./supplier-orders/SupplierOrdersList')
)
const CreateSupplierOrder = lazy( () =>
    import('./supplier-orders/CreateSupplierOrder')
)
const EditSupplierOrder = lazy( () =>
    import('./supplier-orders/EditSupplierOrder')
)
const ViewSupplierOrder = lazy( () =>
    import('./supplier-orders/SupplierOrderDetails')
)
const SupplierOrdersBulkImport = lazy( () =>
    import('./supplier-orders/bulk-import/SupplierOrdersBulkImport')
)
const SupplierOrdersBulkImportWizard = lazy( () =>
    import('./supplier-orders/bulk-import-wizard/SupplierOrdersImportWizard')
)
const DraftOrdersList = lazy( () =>
    import('./draft-orders/DraftOrdersList')
)
const CreateDraftOrder = lazy( () =>
    import('./draft-orders/CreateDraftOrder')
)
const EditDraftOrder = lazy( () =>
    import('./draft-orders/EditDraftOrder')
)

const dataTableRoute = [
    {
        path: "/orders/list",
        component: CustomerOrdersList,
        exact: true,
        key: "View_CoreOrders"
    },
    {
        path: "/orders/:idCustomerOrder([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
        component: EditCustomerOrder,
        exact: true,
        key: "Edit_CoreOrders"
    },
    {
        path: "/orders/bulk-import",
        component: CustomerOrdersBulkImport,
        exact: true,
        key: "BulkImport_CoreOrders"
    },
    {
        path: "/orders/bulk-import-wizard",
        component: CustomerOrdersBulkImportWizard,
        exact: true,
        key: "View_CoreOrdersImportWizard"
    },
    {
        path: "/orders/inbound/list",
        component: InboundOrders,
        key: "View_BoxOrdersInboundOrders"
    },
    {
        path: "/orders/outbound/list",
        component: OutboundOrders,
        key: "View_BoxOrdersOutboundOrders"
    },
    {
        path: "/supplier-orders/list",
        component: SupplierOrdersList,
        exact: true,
        key: "View_CoreOrdersSupplierOrders"
    },
    {
        path: "/supplier-orders/create",
        component: CreateSupplierOrder,
        exact: true,
        key: "Create_CoreOrdersSupplierOrders"
    },
    {
        path: "/supplier-orders/:idSupplierOrder([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
        component: EditSupplierOrder,
        exact: true,
        key: "Edit_CoreOrdersSupplierOrders"
    },
    {
        path: "/supplier-orders/view/:idSupplierOrder([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
        component: ViewSupplierOrder,
        exact: true,
        key: "View_CoreOrdersSupplierOrders"
    },
    {
        path: "/supplier-orders/bulk-import",
        component: SupplierOrdersBulkImport,
        exact: true,
        key: "BulkImport_CoreOrdersSupplierOrders"
    },
    {
        path: "/supplier-orders/bulk-import-wizard",
        component: SupplierOrdersBulkImportWizard,
        exact: true,
        key: "View_CoreOrdersSupplierOrdersImportWizard"
    },
    {
        path: "/draft-orders/list",
        component: DraftOrdersList,
        exact: true,
        key: "View_CoreOrdersDraft"
    },
    {
        path: "/draft-orders/create",
        component: CreateDraftOrder,
        exact: true,
        key: "Create_CoreOrdersDraft"
    },
    {
        path: "/draft-orders/:idDraft([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
        component: EditDraftOrder,
        exact: true,
        key: "Edit_CoreOrdersDraft"
    },
];

export default dataTableRoute;
