import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {isEmpty} from "lodash";
import limApi from "../../apis/limApi";
import {getCountries} from "./countrySlice";
import {escapeSpecialChars, formatDate, replacePlaceholders, sleep} from "../../../@utils";
import sgMailConfig from "../../apis/sgMailConfig";
import {activeLanguage, languageCodeOnly} from "../../../i18n";
import i18n from "i18next";
import sgMail from "../../apis/sgMail";
import {updateCartItems} from "./cartSlice";
import {selectUserIdClient, selectUserIdClientAccount} from "./userSlice";
import {getCustomerOrderShipments, mapCustomerOrder} from "../../views/orders/ordersUtils";
import teliaeClient from "../../apis/teliaeClient";
import {getCustomerOrdersShippingMethods} from "./shippingMethodSlice";
import {
    addAdvancedSearchProductThunkCases,
    createAdvancedSearchProductThunk, resetAdvancedSearchProduct
} from "../thunks/createAdvancedSearchProductThunk";
import {createGetAddressBookUserBookByIdsThunk} from "../thunks/createGetAddressBookUserBookByIdsThunk";

const customerOrderPostRequest = {
    idClientAccount: "{{idClientAccount}}",
    idUser: "{{idUser}}",
    orderReason: "{{shippingMethod.orderReason}}",
    campaign: null,
    eventName: null,
    eventBeginDate: null,
    eventEndDate: null,
    requestIdContact: "{{idUser}}",
    requestReference: "{{requestReference}}",
    requestLastname: "{{requestLastname}}",
    requestFirstname: "{{requestFirstname}}",
    requestPhone: "{{requestPhone}}",
    requestMobile: "{{requestMobile}}",
    requestEmail: "{{requestEmail}}",
    principalReference: null,
    principalLastname: null,
    principalFirstname: null,
    principalPhone: null,
    principalMobile: null,
    principalEmail: null,
    buyerReference: null,
    buyerLastname: null,
    buyerFirstname: null,
    buyerPhone: null,
    buyerMobile: null,
    buyerEmail: null,
    buyerCompany: null,
    buyerAddress1: null,
    buyerAddress2: null,
    buyerZipCode: null,
    buyerCity: null,
    buyerCountryISO2: null,
    buyerComment: null,
    deliveries: [
        {
            idWarehouse: "{{idWarehouse}}",
            requestedDeliveryDate: "{{deliveryDate}}",
            idAddressBookAddress: "{{idAddressBookAddress}}",
            deliveryAddressType: "{{addressType}}",
            deliveryAddressReference: "{{reference}}",
            deliveryAddressFirstname: "{{firstname}}",
            deliveryAddressLastname: "{{lastname}}",
            deliveryAddressCompanyName: "{{companyName}}",
            deliveryAddress1: "{{address.street}}",
            deliveryAddress2: "{{address.street2}}",
            deliveryAddressZipCode: "{{address.postCode}}",
            deliveryAddressCity: "{{address.city}}",
            deliveryAddressCountryISO2: "{{address.country}}",
            deliveryAddressCountry: "{{countryName}}",
            deliveryInstruction: "{{deliveryInstruction}}",
            deliveryAddressMobile: "{{mobile}}",
            deliveryAddressPhone: "{{phone}}",
            deliveryAddressEmail: "{{email}}",
            deliveryAddressComment: "{{comment}}",
            deliveryAddressOpeningSchedules: "{{openingSchedules}}",
            appointmentRequired: false,
            appointmentDateBegin: null,
            appointmentDateEnd: null,
            appointmentComment: null,
            idRelayPointAddress: null,
            idShippingMethod: "{{shippingMethod.idShippingMethod}}",
            transportRequired: "{{shippingMethod.isTransportRequired}}",
            transportType: "{{shippingMethod.transportType}}",
            products: [],
        },
    ],
};

const executePostCustomerOrder = async (data, state) => {
    const variables = {
        idClientAccount: data.idClientAccount,
        idUser: data.user.idUser,
        requestReference: data.user.reference,
        requestLastname: data.user.lastName,
        requestFirstname: data.user.firstName,
        requestPhone: data.user.phone,
        requestMobile: data.user.mobile,
        requestEmail: data.user.email,
        ...data.shipping,
        deliveryDate: data.shipping.deliveryDate instanceof Date ? data.shipping.deliveryDate.toISOString() : data.shipping.deliveryDate,
        idWarehouse: state.warehouse.active.idWarehouse,
        countryName: state.country.list.find((country) => country.isO2 === data.shipping.address.country).countryNameEN,
        openingSchedules: escapeSpecialChars(data.shipping.openingSchedules),
        comment: escapeSpecialChars(data.shipping.comment)
    };

    const templateData = replacePlaceholders(customerOrderPostRequest, variables);
    templateData.deliveries[0].transportRequired = data.shipping.shippingMethod.isTransportRequired;
    templateData.deliveries[0].products = data.orderItems.map((item) => ({
        idProduct: item.idProduct,
        orderedQuantity: item.quantity
    }));

    templateData.documents = data.shipping.documents.map(document => ({
        document: document.base64.split(',')[1],
        documentDescription: document.fileTitle,
        documentName: document.name,
        documentFileName: document.name,
        documentSize: parseInt(document.size),
        displayPosition: document.displayPosition,
        fileType: document.ext
    }));

    const response = await limApi.post('/customerorders', templateData).then(async res => await res.data.customerOrder);

    const msg = {
        from: sgMailConfig.from,
        personalizations: [{
            to: [
                {
                    email: data.user.email
                }
            ],
            dynamic_template_data: {
                user: {
                    firstName: data.user.firstName,
                    lastName: data.user.lastName
                },
                order: {
                    orderNumber: response.orderNumber,
                    requestedDeliveryDate: formatDate(response.deliveries[0].requestedDeliveryDate),
                    inboundName: response.deliveries[0].inboundName,
                    deliveryAddress1: response.deliveries[0].deliveryAddress1,
                    deliveryAddress2: response.deliveries[0].deliveryAddress2,
                    deliveryAddressZipCode: response.deliveries[0].deliveryAddressZipCode,
                    deliveryAddressCity: response.deliveries[0].deliveryAddressCity,
                    deliveryAddressCountryISO2: state.country.list.find((country) => country.isO2 === response.deliveries[0].deliveryAddressCountryISO2).countryNameEN,
                    outboundName: response.deliveries[0].outboundName,
                    outboundAddress1: state.warehouse.active.address1,
                    outboundAddress2: state.warehouse.active.address2,
                    outboundZipCode: state.warehouse.active.zipCode,
                    outboundCity: state.warehouse.active.city,
                    outboundCountry: state.warehouse.active.country,
                    products: response.deliveries[0].products
                },
                appUrl: sgMailConfig.appUrl
            }
        }],
        template_id: sgMailConfig.templatesId.order.confirmation[languageCodeOnly(data.user.cultureLanguageCode)]
    };

    await sgMail.send(msg).catch(err => {
        console.error(err)
    });

    return response;
}

const executePutCustomerOrder = async (data, state) => {
    const values = {
        idCustomerOrder: data.idCustomerOrder,
        idClientAccount: data.idClientAccount,
        idUser: data.idUser,
        orderReference: data.orderReference,
        orderComment: escapeSpecialChars(data.orderComment),
        poNumber: data.poNumber,
        // Delivery
        idCustomerOrderDelivery: data.idCustomerOrderDelivery,
        idShippingMethod: data.shippingMethodObj.idShippingMethod,
        idAddressBookAddress: data.idAddressBookAddress,
        idWarehouse: data.idWarehouse,
        requestedDeliveryDate: data.requestedDeliveryDate instanceof Date ? data.requestedDeliveryDate.toISOString() : data.requestedDeliveryDate,
        boxUserFirstName: data.boxUserFirstName,
        boxUserLastName: data.boxUserLastName,
        boxUserEmail: data.boxUserEmail,
        boxUserPhone: data.boxUserPhone,
        boxUserMobile: data.boxUserMobile,
        addressComment: data.addressComment,
        products: data.products
    };

    const customerOrder = await limApi.put(`/customerorders/${data.idCustomerOrder}`, values).then(async res => await res.data.customerOrder);

    const orderWarehouse = state.warehouse.list.find(warehouse => warehouse.warehouseName === data.outboundName);

    const msg = {
        from: sgMailConfig.from,
        personalizations: [{
            to: [
                {
                    email: data.userEmail
                }
            ],
            dynamic_template_data: {
                user: {
                    firstName: data.requestFirstname,
                    lastName: data.requestLastname
                },
                order: {
                    orderNumber: customerOrder.orderNumber,
                    requestedDeliveryDate: formatDate(customerOrder.deliveries[0].requestedDeliveryDate),
                    inboundName: customerOrder.deliveries[0].inboundName,
                    deliveryAddress1: customerOrder.deliveries[0].deliveryAddress1,
                    deliveryAddress2: customerOrder.deliveries[0].deliveryAddress2,
                    deliveryAddressZipCode: customerOrder.deliveries[0].deliveryAddressZipCode,
                    deliveryAddressCity: customerOrder.deliveries[0].deliveryAddressCity,
                    deliveryAddressCountryISO2: state.country.list.find((country) => country.isO2 === customerOrder.deliveries[0].deliveryAddressCountryISO2).countryNameEN,
                    outboundName: customerOrder.deliveries[0].outboundName,
                    outboundAddress1: orderWarehouse.address1,
                    outboundAddress2: orderWarehouse.address2,
                    outboundZipCode: orderWarehouse.zipCode,
                    outboundCity: orderWarehouse.city,
                    outboundCountry: orderWarehouse.country,
                    products: customerOrder.deliveries[0].products
                },
                appUrl: sgMailConfig.appUrl
            }
        }],
        template_id: sgMailConfig.templatesId.order.confirmation[languageCodeOnly(activeLanguage)]
    };

    await sgMail.send(msg).catch(err => {
        console.error(err)
    });

    return customerOrder;
}

const executeCancelCustomerOrder = async (customerOrder, state) => {
    const delivery = customerOrder.deliveries[0];

    const orderWarehouse = state.warehouse.list.find(warehouse => warehouse.warehouseName === delivery.outboundName);

    const msg = {
        from: sgMailConfig.from,
        personalizations: [{
            to: [
                {
                    email: customerOrder.requestEmail
                }
            ],
            dynamic_template_data: {
                user: {
                    firstName: customerOrder.requestFirstname,
                    lastName: customerOrder.requestLastname
                },
                order: {
                    orderNumber: customerOrder.orderNumber,
                    requestedDeliveryDate: formatDate(delivery.requestedDeliveryDate),
                    inboundName: delivery.inboundName,
                    deliveryAddress1: delivery.deliveryAddress1,
                    deliveryAddress2: delivery.deliveryAddress2,
                    deliveryAddressZipCode: delivery.deliveryAddressZipCode,
                    deliveryAddressCity: delivery.deliveryAddressCity,
                    deliveryAddressCountryISO2: state.country.list.find((country) => country.isO2 === delivery.deliveryAddressCountryISO2).countryNameEN,
                    outboundName: delivery.outboundName,
                    outboundAddress1: orderWarehouse.address1,
                    outboundAddress2: orderWarehouse.address2,
                    outboundZipCode: orderWarehouse.zipCode,
                    outboundCity: orderWarehouse.city,
                    outboundCountry: orderWarehouse.country,
                    products: delivery.products
                },
                appUrl: sgMailConfig.appUrl
            }
        }],
        template_id: sgMailConfig.templatesId.order.cancellation[languageCodeOnly(activeLanguage)]
    };

    await sgMail.send(msg).catch(err => {
        console.error(err)
    });

    return customerOrder;
}

export const getCustomerOrders = createAsyncThunk(
    'customerOrders/fetchAll',
    async ({queryParams = ""}, {rejectWithValue, getState}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/customerorders?${queryParams}`);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getCustomerOrder = createAsyncThunk(
    'customerOrders/fetchOne',
    async (idCustomerOrder, {rejectWithValue, getState}) => {
        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/customerorders/${idCustomerOrder}`);
            return response.data.customerOrder;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

const getCustomerOrderHelperData = (order) => async (dispatch) => {
    const delivery = order.deliveries?.[0];

    if (delivery) {
        await dispatch(customerOrdersGetAddress({idUser: order.idUser, addresses: [delivery.idAddressBookAddress]}));

        await dispatch(getCustomerOrdersShippingMethods())
            .unwrap()
            .then(response => {
                const shippingMethod = response.find(shippingMethod => shippingMethod.idShippingMethod === delivery.idShippingMethod)
                dispatch(setShippingMethod(shippingMethod));
            });
    }
}

export const getCustomerOrderEdit = createAsyncThunk(
    'customerOrders/fetchOneEdit',
    async (idCustomerOrder, {rejectWithValue, getState, dispatch}) => {
        try {
            const state = getState();
            const idClient = selectUserIdClient(state);
            const idClientAccount = selectUserIdClientAccount(state);

            const order = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/customerorders/${idCustomerOrder}`).then(response => response.data.customerOrder);

            await dispatch(getCustomerOrderHelperData(order));
            const customerOrdersState = getState().customerOrders;

            const orderEdit = mapCustomerOrder(order, customerOrdersState.selectedAddress);

            return {order, orderEdit};
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const postCustomerOrder = createAsyncThunk(
    'customerOrders/create',
    async (data, {rejectWithValue, getState, dispatch}) => {
        if (isEmpty(getState().country.list)) {
            await dispatch(getCountries());
        }

        try {
            let response;

            if (data.shipping.shipToMultiple && data.shipping.multipleAddressBookAddresses.length > 0) {
                const addressData = {...data};
                let i = 0;
                for (const idAddressBookAddress of data.shipping.multipleAddressBookAddresses) {
                    const address = data.shipping.addresses.find(address => address.idAddressBookAddress === idAddressBookAddress);
                    if (address) {
                        addressData.shipping.idAddressBookAddress = idAddressBookAddress;
                        addressData.shipping.address = {
                            country: address.countryISO2,
                            street: address.address1,
                            street2: address.address2,
                            postCode: address.zipCode,
                            city: address.city
                        }
                        addressData.shipping.addressType = address.addressType;
                        addressData.shipping.reference = address.reference;
                        addressData.shipping.firstname = address.firstname;
                        addressData.shipping.lastname = address.lastname;
                        addressData.shipping.companyName = address.companyName;
                        addressData.shipping.deliveryInstruction = address.deliveryInstruction;
                        addressData.shipping.mobile = address.mobile;
                        addressData.shipping.phone = address.phone;
                        addressData.shipping.email = address.email;
                        addressData.shipping.comment = address.comment;
                        addressData.shipping.openingSchedules = address.openingSchedules;

                        response = await executePostCustomerOrder(addressData, getState()).catch(e => {
                            if (i === 0) {
                                throw Error(e.message);
                            } else {
                                dispatch(setMultipleAddressError({
                                    code: e.code,
                                    message: i18n.t("Order for {{addressName}} failed with error - {{errorMessage}}", {
                                        addressName: `<strong>${address.addressName}</strong>`,
                                        errorMessage: e.message,
                                        // interpolation: { escapeValue: false }
                                    })
                                }))
                            }
                        });
                        i++;
                    }
                }
            } else {
                response = await executePostCustomerOrder(data, getState());
            }

            return response;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const putCustomerOrder = createAsyncThunk(
    'customerOrders/update',
    async ({idCustomerOrder, data}, {rejectWithValue, dispatch, getState}) => {
        if (isEmpty(getState().country.list)) {
            await dispatch(getCountries());
        }

        try {
            const order = await executePutCustomerOrder(data, getState());

            await dispatch(getCustomerOrderHelperData(order));
            const customerOrdersState = getState().customerOrders;

            const orderEdit = mapCustomerOrder(order, customerOrdersState.selectedAddress);

            return {order, orderEdit};
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const cancelCustomerOrder = createAsyncThunk(
    'customerOrders/cancel',
    async ({idCustomerOrder, data}, {rejectWithValue, dispatch, getState}) => {
        if (isEmpty(getState().country.list)) {
            await dispatch(getCountries());
        }

        try {
            const idClient = selectUserIdClient(getState());
            const idClientAccount = selectUserIdClientAccount(getState());

            const order = await dispatch(getCustomerOrder(idCustomerOrder)).unwrap();
            await executeCancelCustomerOrder(order, getState());

            await limApi.post(`/clients/${idClient}/accounts/${idClientAccount}/customerorders/${idCustomerOrder}/cancel`, data);
            return true;
        } catch (e) {
            return rejectWithValue(e);
        } finally {
            dispatch(resetCustomerOrder());
        }
    }
);

export const bulkCustomerOrderImport = createAsyncThunk(
    'customerOrders/bulkImport',
    async (data, {rejectWithValue}) => {
        try {
            const response = await limApi.post("/customerorders/import", {
                orders: data.orders,
                file: data.file[0],
                idShippingMethod: data.shippingMethod.idShippingMethod
            });
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getCustomerOrderTracking = createAsyncThunk(
    'customerOrders/fetchTracking',
    async (customerOrder, {rejectWithValue}) => {
        try {
            const orderShipments = getCustomerOrderShipments(customerOrder.deliveries);
            const orderShipmentsWithTracking = orderShipments.filter(tracking => tracking.idTracking);

            const trackingData = [];

            for (const shipment of orderShipmentsWithTracking) {
                const {trackingEvents = [], urlCarrierTracking} = await teliaeClient
                    .api(`/v1/order/${shipment.idTracking}/tracking`)
                    .get()
                    .then(response => response.data.response);

                trackingData.push({
                    shipment: {
                        ...shipment,
                        trackingURL: shipment.trackingURL || urlCarrierTracking,
                    },
                    trackingEvents: trackingEvents.reverse()});
            }

            return trackingData;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)

export const customerOrdersGetAddress =  createGetAddressBookUserBookByIdsThunk('customerOrders/getAddress');
export const customerOrdersSearchProduct = createAdvancedSearchProductThunk('customerOrders/searchProduct');

const initialState = {
    list: [],
    listLoading: undefined,
    order: undefined,
    orderEdit: undefined,
    loading: undefined,
    multipleAddressErrors: [],
    orderTracking: undefined,
    orderTrackingLoading: undefined,
    // Edit order helpers
    selectedAddress: undefined,
    shippingMethod: undefined,
    productsSuggestions: undefined,
    suggestionsLoading: undefined,
    errors: [],
};

const customerOrdersSlice = createSlice({
    name: 'customerOrders',
    initialState,
    reducers: {
        resetCustomerOrders: state => {
            state.list = [];
        },
        resetCustomerOrder: state => {
            state.order = undefined;
        },
        resetMultipleAddressErrors: state => {
            state.multipleAddressErrors = [];
        },
        setMultipleAddressError: (state, action) => {
            state.multipleAddressErrors.push(action.payload);
        },
        resetCustomerOrderTracking: state => {
            state.orderTracking = undefined;
        },
        setShippingMethod: (state, action) => {
            state.shippingMethod = action.payload;
        },
        resetCustomerOrderEdit: state => {
            state.order = undefined;
            state.orderEdit = initialState.orderEdit;
            state.selectedAddress = initialState.selectedAddress;
            state.shippingMethod = initialState.shippingMethod;
            state.errors = initialState.errors;
        },
        resetCustomerOrderProductSuggestions: state => resetAdvancedSearchProduct(state, initialState),
        setCustomerOrderErrors: (state, action) => {
            const {idProduct, diff} = action.payload;
            const errorIndex = state.errors.findIndex((err) => err.idProduct === idProduct);

            if (diff < 0 && errorIndex === -1) {
                state.errors.push(action.payload);
            } else if (diff >= 0) {
                state.errors = state.errors.filter((err) => err.idProduct !== idProduct);
            }
        },
        resetCustomerOrderErrors: state => {
            state.errors = initialState.errors;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCustomerOrders.pending, state => {
                state.listLoading = true;
            })
            .addCase(getCustomerOrders.fulfilled, (state, action) => {
                state.listLoading = undefined;
                state.list = action.payload;
            })
            .addCase(getCustomerOrders.rejected, state => {
                state.listLoading = undefined;
            })
            .addCase(getCustomerOrder.pending, state => {
                state.loading = true
            })
            .addCase(getCustomerOrder.fulfilled, (state, action) => {
                state.loading = undefined;
                state.order = action.payload;
            })
            .addCase(getCustomerOrder.rejected, state => {
                state.loading = undefined
            })
            .addCase(getCustomerOrderEdit.pending, state => {
                state.loading = true
            })
            .addCase(getCustomerOrderEdit.fulfilled, (state, action) => {
                state.loading = undefined;
                state.order = action.payload.order;
                state.orderEdit = action.payload.orderEdit;
            })
            .addCase(getCustomerOrderEdit.rejected, state => {
                state.loading = undefined
            })
            .addCase(postCustomerOrder.fulfilled, (state, action) => {
                state.order = action.payload;
            })
            .addCase(putCustomerOrder.fulfilled, (state, action) => {
                state.order = action.payload.order;
                state.orderEdit = action.payload.orderEdit;
            })
            .addCase(getCustomerOrderTracking.pending, state => {
                state.orderTrackingLoading = true;
            })
            .addCase(getCustomerOrderTracking.fulfilled, (state, action) => {
                state.orderTrackingLoading = undefined;
                state.orderTracking = action.payload;
            })
            .addCase(getCustomerOrderTracking.rejected, state => {
                state.orderTrackingLoading = undefined;
            })
            .addCase(customerOrdersGetAddress.fulfilled, (state, action) => {
                state.selectedAddress = action.payload.shift();
            });

            addAdvancedSearchProductThunkCases(builder, customerOrdersSearchProduct)
    }
});

export const reorderCustomerOrder = (order) => async (dispatch) => {
    // Filter only existing products.
    // When product is disabled or deleted the currentProduct property will be null
    const products = order.deliveries[0].products.filter(product => product.currentProduct);

    if (products.length === 0) {
        throw new Error(i18n.t('No eligible products for reorder.'));
    }

    // Do it like this because of the API
    for (const product of products) {
        await sleep(500)
        await dispatch(updateCartItems(product.currentProduct, product.orderedQuantity))
    }
}

export const {
    resetCustomerOrders,
    resetCustomerOrder,
    setMultipleAddressError,
    resetMultipleAddressErrors,
    resetCustomerOrderTracking,
    setShippingMethod,
    resetCustomerOrderEdit,
    resetCustomerOrderProductSuggestions,
    setCustomerOrderErrors,
    resetCustomerOrderErrors
} = customerOrdersSlice.actions;

export default customerOrdersSlice.reducer;