import {createAsyncThunk} from "@reduxjs/toolkit";
import {selectUserIdClient, selectUserIdClientAccount} from "../slices/userSlice";
import limApi from "../../apis/limApi";

export const createGetAddressBookUserBookByIdsThunk = (typePrefix) =>
    createAsyncThunk(
        typePrefix,
        async ({idUser, addresses = [], addAuthUserAddressBook}, {getState, rejectWithValue }) => {
            const state = getState();
            const idClient = selectUserIdClient(state);
            const idClientAccount = selectUserIdClientAccount(state);

            try {
                const jsonParams = {
                    currentPage: 1,
                    sortOrders: [
                        {
                            field: "readOnly",
                            direction: "asc",
                        },
                        {
                            field: "creationDate",
                            direction: "desc"
                        }
                    ],
                    filterGroups: [
                        {
                            filters: [
                                {
                                    field: "enabled",
                                    value: "1",
                                    operator: "eq",
                                },
                            ],
                        },
                        {
                            filters: [
                                {
                                    field: "idAddressBookAddress",
                                    value: addresses.map((idAddressBookAddress) => `'${idAddressBookAddress}'`).join(","),
                                    operator: "in",
                                },
                            ],
                        },
                    ],
                };

                if (addresses.length > 0) {
                    const response = await limApi.put(`/clients/${idClient}/accounts/${idClientAccount}/users/${idUser}/addressbook${addAuthUserAddressBook ? '?addAuthUserAddressBook=true' : ''}`, jsonParams);
                    return response.data.items;
                }
                return [];
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );