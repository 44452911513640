export const customerOrderStatuses = {
    0: 'Annulée',
    1: 'Prise en compte',
    10: 'En Cours',
    30: 'Préparation',
    40: 'Clôturée',
    60: 'Expédiée',
    63: 'Transit',
    64: 'Mis en livraison',
    66: 'Traitée',
    70: 'Livrée'
}

export const supplierOrderStatuses = {
    0: 'Annulée',
    1: 'Attente de réception',
    20: 'Réception en Cours',
    30: 'Reçu partiellement',
    50: 'Réceptionnée',
    60: 'Expiré'
}

export const SUPPLIER_ORDER_TYPE_SUPPLIER = 'Supplier';

export const SUPPLIER_ORDER_TYPE_RETURN = 'Return';

export const supplierOrderTypes = {
    [SUPPLIER_ORDER_TYPE_SUPPLIER]: 'Supplier',
    [SUPPLIER_ORDER_TYPE_RETURN]: 'Return'
}

/**
 * Retrieves tracking information from order deliveries.
 *
 * @param {Array} orderDeliveries - Array of order deliveries.
 * @returns {Array} Array of order shipments objects.
 */
export const getCustomerOrderShipments = (orderDeliveries = []) => {
    const trackingInfo = [];

    for (const delivery of orderDeliveries) {
        if (delivery.shipments) {
            for (const shipment of delivery.shipments) {
                // if (shipment.carrier && shipment.trackingNumber) {
                    trackingInfo.push(shipment);
                // }
            }
        }
    }

    return trackingInfo;
};

export const createFlatShipmentProducts = (customerOrder) => {
    const flatProducts = [];

    // Iterate over each delivery
    customerOrder.deliveries.forEach(delivery => {
        // Iterate over each shipment in the delivery
        delivery.shipments.forEach(shipment => {
            // Iterate over each product in the shipment
            shipment.products.forEach(product => {
                flatProducts.push(product);
            });
        });
    });

    return flatProducts;
};

export const mapCustomerOrder = (data, selectedAddress) => {
    const delivery = data.deliveries?.[0] || {};
    const isBoxAddress = selectedAddress?.addressType === 'BOX';
    const isBoxUser = isBoxAddress && delivery.deliveryAddressFirstname !== selectedAddress.firstname && delivery.deliveryAddressLastname !== selectedAddress.lastname;

    return {
        idCustomerOrder: data.idCustomerOrder,
        idClientAccount: data.idClientAccount,
        idUser: data.idUser,
        userEmail: data.requestEmail,
        userFirstname: data.requestFirstname,
        userLastname: data.requestLastname,
        idCustomerOrderDelivery: delivery.idCustomerOrderDelivery,
        outboundName: delivery.outboundName,
        orderNumber: data.orderNumber,
        orderDate: data.orderDate,
        creationUser: data.creationUser,
        statusCode: data.statusCode,
        statusName: data.statusName,
        idShippingMethod: delivery.idShippingMethod,
        requestedDeliveryDate: delivery.requestedDeliveryDate,
        idAddressBookAddress: delivery.idAddressBookAddress,
        idBoxUser: isBoxUser,
        boxUserFirstName: isBoxUser && delivery.deliveryAddressFirstname ? delivery.deliveryAddressFirstname : null,
        boxUserLastName: isBoxUser && delivery.deliveryAddressLastname ? delivery.deliveryAddressLastname : null,
        boxUserEmail: isBoxUser && delivery.deliveryAddressEmail ? delivery.deliveryAddressEmail : null,
        boxUserPhone: isBoxUser && delivery.deliveryAddressPhone ? delivery.deliveryAddressPhone : null,
        boxUserMobile: isBoxUser && delivery.deliveryAddressMobile ? delivery.deliveryAddressMobile : null,
        addressComment: delivery.deliveryAddressComment,
        orderComment: data.orderComment,
        orderReference: data.buyerReference,
        poNumber: data.poNumber,
        products: delivery.products
            .map(p => ({
                idCustomerOrderDeliveryProduct: p.idCustomerOrderDeliveryProduct,
                idCustomerOrderDelivery: p.idCustomerOrderDelivery,
                idProduct: p.idProduct,
                lineNumber: p.lineNumber,
                productShortName: p.productShortName,
                productName: p.productName,
                sku: p.sku,
                skuClient: p.skuClient,
                priceSell: p.priceSell,
                idImage: p.currentProduct?.idImage,
                orderedQuantity: p.orderedQuantity,
                availableQuantity: (p.currentProduct?.availableQuantity || 0) + p.orderedQuantity,
                recordStatus: 0
            }))
            .sort((a, b) => a.lineNumber - b.lineNumber)
    };
};

