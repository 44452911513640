import {createAsyncThunk} from "@reduxjs/toolkit";
import {selectUserIdClient, selectUserIdClientAccount} from "../slices/userSlice";
import {productTypes} from "../../views/app/catalog-admin/products/form-model/formInitialValues";
import limApi from "../../apis/limApi";

export const createAdvancedSearchProductThunk = (typePrefix) =>
    createAsyncThunk(
        typePrefix,
        async ({query, idUser}, {rejectWithValue, getState}) => {
            try {
                const idClient = selectUserIdClient(getState());
                const idClientAccount = selectUserIdClientAccount(getState());

                const jsonParams = {
                    currentPage: 1,
                    pageSize: 30,
                    filterGroups: JSON.stringify([
                        {
                            filters: [
                                {
                                    field: "productName",
                                    value: `%${query.toLowerCase()}%`,
                                    operator: "like"
                                },
                                {
                                    field: "productShortName",
                                    value: `%${query.toLowerCase()}%`,
                                    operator: "like"
                                },
                                {
                                    field: "sku",
                                    value: `%${query.toLowerCase()}%`,
                                    operator: "like"
                                },
                                {
                                    field: "skuClient",
                                    value: `%${query.toLowerCase()}%`,
                                    operator: "like"
                                }
                            ]
                        },
                        {
                            filters: [{
                                field: "type",
                                value: productTypes["simple"].value,
                                operator: "eq"
                            }]
                        }
                    ])
                }

                const queryParams = new URLSearchParams(jsonParams).toString();
                // Using this endpoint instead products/search because of product type filter
                const response = await limApi.get(`/clients/${idClient}/accounts/${idClientAccount}/products?${queryParams}`,{
                    headers: idUser ? { IdUser: idUser } : {} // search product as order owner for example
                });

                return response.data.items;
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );

export const addAdvancedSearchProductThunkCases = (builder, thunk) => {
    builder
        .addCase(thunk.pending, (state) => {
            state.suggestionsLoading = true;
        })
        .addCase(thunk.fulfilled, (state, action) => {
            state.productsSuggestions = action.payload;
            state.suggestionsLoading = undefined;
        })
        .addCase(thunk.rejected, (state) => {
            state.suggestionsLoading = undefined;
        });
};

export const resetAdvancedSearchProduct = (state, initialState) => {
    state.productsSuggestions = initialState.productsSuggestions;
}