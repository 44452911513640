import axios from 'axios';
import localStorageService from "../services/localStorageService";
import {msalInstance} from "../../index";
import {InteractionRequiredAuthError} from "@azure/msal-browser";
import {loginRequest} from "../auth/authConfig";

export const RECORD_STATUS_IDLE = 0;
export const RECORD_STATUS_NEW = 1;
export const RECORD_STATUS_EDIT = 2;
export const RECORD_STATUS_DELETE = 3;
export const SYSTEM_ADMIN_ROLE = "SystemAdmin";

const limApi = axios.create({
    baseURL: process.env.REACT_APP_IGL_API_HOST,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'IdApplication': process.env.REACT_APP_IGL_API_ID_APPLICATION
    }
})

limApi.interceptors.request.use(
    (request) => {
        request.headers['IdUser'] = request.headers['IdUser']
            || localStorageService.getItem("masquerade")
            || localStorageService.getItem("authUserId");
        request.headers['accessToken'] = localStorageService.getItem("accessToken");

        if (localStorageService.getItem("masqueradeSid")) {
            request.headers['idMasquerade'] = localStorageService.getItem("masqueradeSid")
        }

        return request;
    },
    (error) => {
        return Promise.reject(error);
    }, { synchronous: true });

limApi.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (err.response) {
            if ((err.response.status === 400 || err.response.status === 401) && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    await msalInstance.acquireTokenSilent(loginRequest).then(response => {
                        if (!response.idToken || response.idToken === "") {
                            console.log('no token')
                        }
                    }).catch(async (error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            // fallback to interaction when silent call fails
                            return msalInstance.loginRedirect(loginRequest);
                        }
                    })
                    limApi.defaults.headers.common["IdUser"] = localStorageService.getItem("masquerade") || localStorageService.getItem("authUserId");
                    limApi.defaults.headers.common["accessToken"] = localStorageService.getItem("accessToken");
                    return await limApi(originalConfig);
                } catch (_error) {
                    if (_error.response && _error.response.data) {
                        return Promise.reject(_error.response.data);
                    }
                    return Promise.reject(_error);
                }
            }
            if ((err.response.status === 400 || err.response.status === 401) && err.response.data) {
                return Promise.reject(err.response.data);
            }
        }
        return Promise.reject(err);
    }
);
// limApi.interceptors.response.use(function (response) {
//     //Dispatch any action on success
//     return response;
// }, function (error) {
//     if(error.response.status === 401) {
//         //Add Logic to
//         //1. Redirect to login page or
//         //2. Request refresh token
//     }
//     return Promise.reject(error);
// });

export default limApi;